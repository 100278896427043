<template>
    <div class="flex">
        <div class="header">
            <div class="container">
                <NuxtLink to="/" class="header__logo">
                    <AtomIcon name="logo" width="212" />
                </NuxtLink>
            </div>

            <div class="nav"/>
        </div>

        <slot />

        <div class="footer"/>
    </div>
</template>

<style lang="scss" scoped>
.flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    padding-top: 2rem;

    .nav {
        height: rem(52);
        margin-top: rem(32);

        @include helper-color-bg(strong-blue);
    }
}

.footer {
    flex: 1;
    min-height: rem(100);

    @include helper-color-bg(strong-blue);
}
</style>
